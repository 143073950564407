<template>
    <div class="helpCenter">
          <transition name="fade" class="index">
            <preLoading v-if="isLoading"></preLoading>
        </transition>
         <van-nav-bar
                title="帮助中心"
                left-arrow
                @click-left="onClickLeft"
                class="dark_bg"
        />
        <!-- 提问环节 -->
        <div class="put-questions-to">
           <div v-for="item in data_question" :key="item.data_question">
                <van-cell
                        :title="item.question"
                        is-link
                        class="dark_shop_bg"
                        :to="{name:'helpCenterDetails',query:{item:JSON.stringify(item)}}"      
                >
                <span
                        slot="icon"
                        style="color: #d14a4a"
                ></span>
            </van-cell>
            <van-cell v-show="show = false">
                <span
                ></span>
            </van-cell>
           </div>
            
        </div>
    </div>
</template>

<script>

import {Cell, CellGroup, Popup, Loading} from "vant";
export default{
    data(){
        return{
            data_question:[],
            isLoading:true,

        }
    },
     components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Popup.name]: Popup,
            [Loading.name]: Loading,
        },
    created(){
        this.getHelpCenterList();
    },
    methods:{
        onClickLeft() {
            this.$router.back();
        },
        getHelpCenterList(){
            this.isLoading = true;
            this.$api.my.getHelpCenterList({
                parent_Id:0,
            }).then((res) =>{
                if (res.errno == 200) {
                    console.log(res.data);
                    this.data_question = res.data;
                    this.isLoading = false;
                }
            })
        }
    } 
}
</script>

<style scoped lang="less">
   @import "../../../less/element.less";

    @red: #c83f3f;
    .checked {
        color: #c83f3f;
        position: absolute;
        top: 3.1 / 3.125rem;
        right: 1.5 / 3.125rem;
        z-index: 2002;
    }
    .helpCenter{
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 2 / 3.125rem;
        .put-questions-to{
            margin-top: .2rem /* 10/50 */;
        }
    }
    .index{
        z-index: 9999;
    }
</style>